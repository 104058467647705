import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BrError = () => {

	const data = useStaticQuery(graphql`
	query {
		gwLogoOrange: file(relativePath: { eq: "gw-logo-orange.png" }) {
			childImageSharp {
				fixed(height: 60) {
					...GatsbyImageSharpFixed
				}
			}
		},
		warning: file(relativePath: { eq: "illust/warning.png" }) {
			childImageSharp {
				fluid(maxWidth: 300, quality: 50) {
					...GatsbyImageSharpFluid
					...GatsbyImageSharpFluidLimitPresentationSize
				}
			}
		},
		site {
			siteMetadata {
				title
				author
			}
		}
	}
	`)

	return (
		<>
			<div style={{ marginTop: '8rem', textAlign: 'center' }} >
				<div><Img fixed={data.gwLogoOrange.childImageSharp.fixed} alt={data.site.siteMetadata.title} /></div>

				<div
					style={{
						display: 'inline-block',
						margin: '2rem auto',
						padding: '5.6rem 4rem',
						boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
					}}
				>
					<h1>現在、このブラウザはサポートされていません。</h1>
					<p>恐れ入りますが、他のブラウザをお使いの上アクセスしてください。</p>
					<Img fluid={data.warning.childImageSharp.fluid} alt="ブラウザエラー" style={{ margin: '4rem auto 0'}} />
				</div>

				<p>© {new Date().getFullYear()}{` `}{data.site.siteMetadata.author}</p>
			</div>
		</>
	)
}

export default BrError